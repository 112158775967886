@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Work Sans', sans-serif;
  background: black;
  color: #DADADB;
  margin: 0 auto;
  height: 100vh;
}

.App {
  height: max-content;
  padding: 50px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.loading {
  width: 100%;
  text-align: center;
  padding-top: 28%;
  height: unset;
  display: inline-block;
  position: fixed;
  top: 0px;
  background: #000D;
  left: 0px;
  height: 100vh;
}

.footer {
  position: fixed;
  bottom: 0;
  text-align: justify;
  font-size: 14px;
  padding: 10px;
}

@media (max-width: 570px) {

  .App {
    padding: 25px;
  }

  .invisibleIfSmall {
    display: none; 
  }

  .inputContainer {
    position: fixed;
    bottom: 32px;
    width: calc(100% - 50px) !important;
  }

  .formDiv {
    zoom: 0.7;
  }
}

.invisibleIfSmall {}

.headerSubDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 50px);
}

.headerSubText {
  text-align: justify;
  font-size: 16px;
  min-width: 240px;
  width: calc(80% - 200px);
  background: #FFF4;
  padding: 28px;
  border-radius: 21px;
}

.headerSubTextBot {
  text-align: justify;
  font-size: 16px;
  width: 100%;
  background: #FFF4;
  padding: 28px;
  border-radius: 5px;
  max-height: calc(100vh - 245px);
  position: static;
  z-index: 10;
}

.inputContainer {
  position: fixed;
  bottom: 32px;
  width: calc(100% - 90px);
}

.formDiv {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  align-items: baseline;
  justify-content: center;
}

.formControl {
  flex-grow: 1;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 6px 8px;
  width: 60%;
}

.formControl:focus {
  border-left: 2px solid #000;
  border-top: 2px solid #000;
  border-top-left-radius: 0%;
  border-bottom-left-radius: 0%;
  border-top-right-radius: 0%;
}

.formBtn {
  margin-left: 10px;
  background-color: #ffda3d;
  color: #000000;
  border: none;
  border-radius: 4px;
  padding: 12px 12px;
  cursor: pointer;
  margin-top: 10px;
}

.formBtn:hover {
  font-weight: bolder;
  border-radius: 0;
}

.answerDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px;
}

.answerSubDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
}

.hrLine {
  width: 800px;
}

.answerContainer {
  width: 800px;
}

.question {
  /* border: .5px solid #fff; */
  padding: 25px;
  margin-top: 10px;
  background: #4169E1;
  font-weight: bold;
}

.answer {
  /* border: .5px solid #fff; */
  padding: 30px 25px;
  line-height: 1.5em;
  background: #343536;
}

/* Estilizando a barra de rolagem */
::-webkit-scrollbar {
    width: 8px;      /* Largura da barra de rolagem vertical */
    height: 8px;     /* Altura da barra de rolagem horizontal */
}

/* Cor do fundo da barra de rolagem */
::-webkit-scrollbar-track {
    background: #f1f1f1;  /* Cor de fundo da área da barra */
    border-radius: 10px;  /* Borda arredondada */
}

/* Estilizando o polegar da barra de rolagem (a parte que você move) */
::-webkit-scrollbar-thumb {
    background-color: #888;  /* Cor do polegar (cinza) */
    border-radius: 10px;     /* Borda arredondada para suavidade */
    border: 2px solid transparent; /* Borda interna para dar espaçamento */
}

/* Alterar a cor do polegar ao passar o mouse */
::-webkit-scrollbar-thumb:hover {
    background-color: #555;  /* Um cinza mais escuro ao passar o mouse */
}

/* .App {
  
} */
