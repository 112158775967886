@media print {
  * {
    page-break-inside: avoid; /* Evita quebras dentro de elementos */
  }

  @page {
    size: auto; /* Define o tamanho automático baseado no conteúdo */
    margin: 10mm; /* Define margens para evitar cortes */
  }

  html, body {
    height: 100%;
    overflow: visible;
  }
}

@media (min-width: 571px) and (max-width: 820px) {
    .container {
        zoom: 0.8;
    }
    .nav-tabs {
        zoom: 0.9;
    }
    .tab2 {
        display: none;
    }
}

@media (max-width: 570px) {
    .container {
        zoom: 0.6;
    }
    .nav-tabs {
        zoom: 0.7;
    }
    .tab2 {
        display: none;
    }
    .tab4 {
        display: none;
    }
}

.container {
    background-color: #0005;
    padding: 1px 20px 20px 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
    color: white;
    overflow: auto;
    max-width: unset;
    margin-top: 10px;
}
.nav-link {
    color: #ffffff !important;
}
.nav-tabs .nav-link.active {
    color: #333 !important;
}
h1 {
    font-size: 28px;
    color: #ffffff;
    margin-bottom: 20px;
    font-weight: bold;
}
p {
    font-size: 18px;
    line-height: 1.6;
    color: #ffffff;
}
.highlight {
    color: #ffcc00;
    font-weight: bold;
}
.formula, .rationale, .latex {
    margin-top: 20px;
    padding: 15px;
    background-color: rgba(255, 255, 255, 0.1);
    border-left: 5px solid #ffcc00;
    border-radius: 5px;
    text-align: left;
    color: white;
}
.formula code, .latex code {
    font-size: 16px;
    color: white;
    white-space: pre-wrap;
    word-wrap: break-word;
    overflow-wrap: break-word;
}
.lastone {
    margin-bottom: 20px;
}
.rationale {
    border-left-color: #ffcc00;
}
.parameters {
    margin-top: 20px;
    font-size: 16px;
    line-height: 1.4;
    color: rgb(255 226 123);
}
li {
    margin-left: 40px;
    margin-top: 5px;
    margin-bottom: 5px;
}