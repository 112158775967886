.logout-icon {
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #ffd700;
  transition: transform 0.2s;  
}

.logout-icon:hover {
  transform: scale(1.2);
}

.memory-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #ffd700;
  transition: transform 0.2s;
}

.memory-icon:hover {
  transform: scale(1.2);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 1200px;
  zoom: 0.7;
  max-height: 120vh;
  overflow: auto;
}

.crud-container {
  margin-top: 20px;
}

.input-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.input-item {
  flex: 1;
  padding: 8px;
  margin-right: 10px;
}

.trash-icon {
  cursor: pointer;
  color: #af0000;
  font-size: 20px;
  transition: transform 0.2s;
}

.trash-icon:hover {
  transform: scale(1.2);
}

.add-button,
.save-button {
  margin-top: 10px;
  padding: 8px 16px;
  cursor: pointer;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.add-button:hover,
.save-button:hover {
  background-color: #218838;
}
